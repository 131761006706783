import { detect } from 'detect-browser'

// 判断访问终端
export const browser = {
  version: (function () {
    const ua = window.navigator.userAgent
    return {
      isMobile: !!ua.match(/AppleWebKit.*Mobile.*/),
      isIOS: !!ua.match(/Mac OS X/),
      isAndroid: ua.includes('Android') || ua.includes('Adr') || ua.includes('Linux'),
      isIPhone: ua.includes('iPhone'),
      isIPad: ua.includes('iPad'),
      isWeixin: !!ua.match(/MicroMessenger/gi),
      isAliPay: !!ua.match(/Alipay/gi),
      isQQ: !!ua.match(/qq/gi),
      isFirefox: ua.includes('Firefox'),
      isChrome: /Chrome/.test(ua) && /Google Inc/.test(navigator.vendor),
    }
  }()),
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
}

export function closeWindow() {
  if (typeof window.WeixinJSBridge !== 'undefined') {
    window.WeixinJSBridge.call && window.WeixinJSBridge.call('closeWindow')
  } else if (browser.version.isFirefox || browser.version.isChrome) {
    window.location.href = 'about:blank'
    window.close()
  } else if (window) {
    window.opener = null
    window.open('about:blank', '_self')
    window.close()
  }
}

export const isNormalBrowser = () => {
  const { name, version } = detect()
  const methodSupported = Object.entries && Object.fromEntries
   && String.prototype.matchAll && Promise.allSettled
  if (name.toLowerCase() === 'chrome') {
    const firstVersionNumber = Number(version.split('.')?.[0] ?? 0)
    return methodSupported && firstVersionNumber > 70
  }
  return methodSupported
}

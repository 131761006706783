export const formatNumber = (num: number | string, fixed = 2) => {
  if (Number(num) === 0) {
    return 0
  }
  return Number(Number(num).toFixed(fixed))
}

export const formatNumberIndex = (num: number | string) => {
  if (Number(num) < 10) {
    return `0${num}`
  }
  return num
}

export const formatPhoneNumber = (phoneNum?: string) => {
  if (!phoneNum) {
    return '-'
  }
  return `${phoneNum.substring(0, 3)}****${phoneNum.substring(7, 11)}`
}

export const formatIdentityCard = (identityCard?: string) => {
  if (!identityCard) {
    return '-'
  }
  const hideStr = '*'.repeat(identityCard.length - 3)
  return `${identityCard.slice(0, 1)}${hideStr}${identityCard.slice(-2)}`
}

export const getBirthFromIdentityCard = (no = '', isTime = true, split = '-'): number | string => {
  if (!no) {
    return no
  }
  const toStr = no.toString()
  const yearStr = toStr.substring(6, 10)
  const dateStr = `${toStr.substring(10, 12)}${split}${toStr.substring(12, 14)}`
  const fullDateStr = `${yearStr}${split}${dateStr}`
  return isTime ? new Date(fullDateStr).getTime() : fullDateStr
}

export const getSexFromIdentityCard = (no = '') => {
  if (!no) {
    return no
  }
  const toStr = no.toString()
  if (toStr.length < 17) {
    return no
  }
  return Number(toStr.substring(16, 17)) % 2 ? '男' : '女'
}

export const numberToChinese = (num: number): string => {
  const units = ['', '十', '百', '千', '万', '亿']
  const digits = '零一二三四五六七八九'

  if (num === 0) {
    return digits[0]!
  }

  let result = ''
  let unitPos = 0
  let zero = true

  while (num > 0) {
    const digit = num % 10
    if (digit === 0) {
      if (!zero) {
        zero = true
        result = digits[0] + result
      }
    } else {
      zero = false
      result = digits[digit]! + units[unitPos] + result
    }
    unitPos++
    num = Math.floor(num / 10)
  }

  if (result.startsWith('一十')) {
    result = result.substring(1)
  }

  return result
}
